* {
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

body {
  height: 100%;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

h1 {
  text-align: center;
  padding-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
}
/* parallax CSS */
#parallax {
  /* Full height */
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  position: relative;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* transition: background-image 2s ease-in-out; */
}

#noparallax {
  position: relative;
  overflow-x: hidden;
  width: 150vw;
  height: 100vh;
  margin-left: 50%;
  transform: translateX(-50%);
}

#frontpageimg {
  width: 150vw;
  height: 100vh;
  position: absolute;
}

/* black_logo CSS */
/* #black-logo {
  display: block;
  text-align: center;
  padding-top: 33vh;
}

#black-logo-img {
  width: 11vw;
} */

#black-logo {
  /* position: absolute;
  bottom: 0;
  left: 45%; */
  position: absolute;
  bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

#black-logo-img {
  width: 11vw;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  animation-name: fade;
  animation-duration: 2s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

/* MainBar CSs */
.NavLogo {
  display: none;
  float: left;
  margin-left: 5%;
  margin-top: 10px;
  width: 10vw;
  height: 15vh;
}

.nav1 {
  text-align: center;
  padding-bottom: 10px;
}

.ul1 {
  display: block;
  text-align: right;
  margin-right: 3%;
  padding-top: 2%;
}

.ul1 li {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  margin-right: 4%;
  list-style: none;
  float: right;
  position: relative;
}

nav a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 20px;
}

.active_section,
nav a:hover {
  text-decoration: none;
  color: #846546;
  transition: all ease-in-out 200ms;
}

nav a::before {
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  background-color: peru;

  position: absolute;
  top: 100%; /* sets the hover black bar under the list names */
  width: 0%;

  transition: all ease-in-out 200ms;
}

nav a:hover::before {
  width: 100%;
}

li a {
  color: black;
}

#FB,
#IG {
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

/* Homepage logo CSS */
#homepage-logo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0;
  margin-bottom: 3%;
}

/* Secondary Bar CSS */
.NavBar2 {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #4e2d08;
  z-index: 10000;
  height: 8vh;
}

.ul2 {
  display: block;
  text-align: right;
  margin-right: 3%;
  padding-top: 2.5vh;
}

.ul2 li {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  margin-right: 4%;
  letter-spacing: 0px;
  list-style: none;
  position: relative;
}

#li20,
#li21,
#li22,
#li23 {
  color: white;
}

.NavLogo2 {
  float: left;
  margin-left: 2%;
  margin-top: 0.1%;
  height: 7.5vh;
}

#mobileLogodiv {
  display: none;
}
/* Secondary Bar CSS */

/* mobile-nav */
.mobile-nav div {
  width: 25px;
  height: 3px;
  background-color: peru;
  margin: 5px;
}

.mobile-nav {
  display: none;
  cursor: pointer;
}
/* mobile-nav */

/* p tag CSS */
p {
  text-align: center;
  color: #777777;
  /* color: white; */
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}
/* p tag CSS */

/* About CSS */
#About {
  height: 100vh;
  background-color: white;
  /* background-color: #8a4500; */
}

#About_img {
  width: 30%;
  margin-top: 17%;
  margin-left: -10%;
}

#AboutUs {
  display: block;
  padding-top: 30vh;
}

#AboutHeader {
  font-size: 60px;
  margin-bottom: 1%;
}

#AboutP {
  text-align: left;
  font-size: 17px;
}

#about_div {
  display: block;
  text-align: center;
}

#carousel {
  float: left;
  width: 30vw;
  margin-left: 16%;
  margin-top: 10%;
  margin-right: 7vw;
}

/* sub-css for Modal */
#carousel2 {
  float: left;
  width: 900px;
}
/* sub-css for Modal end*/

.Carouselimg {
  border-radius: 50%;
  height: 60vh;
}
/* About CSS */

/* Menu CSS */
.Menu {
  padding-bottom: 5%;
}

#menu_h1,
#menu_p {
  color: white;
}
/* Menu CSS */

/* Contact CSS */
#Contact_div {
  position: relative;
}
#footer {
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
}

#contact_h1,
#contact_p,
.contact_h4,
#contact_h4 {
  color: white;
}
/* Contact CSS */

/* Modal CSS */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 1500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
/* Modal CSS end */

/* mobile CSS */
@media screen and (max-width: 900px) {
  body {
    overflow-x: hidden;
  }

  #black-logo-img2 {
    position: absolute;
    width: 50vw;
    bottom: 10%;
    text-align: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .nav1 {
    height: 8vh;
    width: 100%;
    position: fixed;
    z-index: 1000;
    background-color: #4e2d08;
  }

  /* the little logo in middle for modbile */
  .NavLogo {
    display: block;
    position: relative;
    width: 6vh;
    height: 6vh;
    margin-top: 1vh;
    z-index: 1001;
  }

  #test {
    float: left;
    left: 50%;
    right: 50%;
    margin-left: -6vw;
  }
  /* the little logo in middle for modbile END*/

  .ul1 {
    position: absolute;
    right: 0;
    margin-right: 0px;
    height: 92vh;
    top: 8vh;
    background-color: #4e2d08;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    transform: translateX(100%);
    transition: transform 0.3s ease-in;
    z-index: 10000;
    opacity: 0.9;
  }

  .ul1 li {
    margin-top: 40px;
    animation-delay: 0.5s;
  }

  li a {
    color: white;
  }

  .mobile-nav {
    display: inline-block;
    position: absolute;
    margin-top: 2vh;
    margin-right: 5px;
    right: 0;
  }

  #mobileLogodiv {
    display: block;
    text-align: center;
    padding-top: 10vh;
    margin-bottom: 15%;
  }
  /* Secondary Bar CSS */
  .NavBar2 {
    display: none;
  }

  /* mobile 404 Error CSS */
  .Error_404 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 40vh;
  }

  .Error_jpg {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  /* mobile Menu CSS */
  #Menu {
    margin-top: 0px;
    padding-top: 0px;
  }

  /* Mobile About CSS */
  #About {
    position: relative;
    height: 115vh;
    padding-top: 20%;
  }

  #AboutUs {
    display: block;
    text-align: center;
    padding-top: 15%;
  }

  #about_div {
    display: block;
    text-align: center;
  }

  #About_img {
    width: 100%;
    height: 100%;
    margin-left: 2%;
  }

  #AboutHeader {
    font-size: 35px;
    text-align: center;
    padding-bottom: 0%;
  }

  #AboutP {
    font-size: 13px;
    text-align: center;
  }

  #carousel {
    float: none;
    width: 90vw;
    margin-top: 0%;
    padding-top: 10%;
    margin-left: 5%;
  }

  /* sub-css for Modal */
  #carousel2 {
    float: left;
    width: 95vw;
  }
  /* sub-css for Modal end*/

  .Carouselimg {
    height: 400px;
  }
  /* Mobile About CSS end */

  /* Mobile Arrow CSS */
  #Arrow_img {
    position: fixed;
    width: 70px;
    bottom: 0px;
    right: 0px;
    padding-right: 5%;
    padding-bottom: 5%;
    z-index: 50;
  }
  /* Mobile Arrow CSS end */

  /* Mobile background_logo CSS */
  /* #black-logo {
    padding-top: 39vh;
    padding-left: 3vw;
  }

  #black-logo-img {
    width: 35%;
  } */
  /* #black-logo {
    position: absolute;
    bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  #black-logo-img {
    width: 50%;
  } */

  h1 {
    font-size: 40px;
  }

  #div {
    display: flex;
    justify-content: center;
  }

  #diva {
    padding-top: 0;
  }

  #a {
    position: absolute;
    padding-top: 0.5vh;
  }

  #maskdiv {
    padding-bottom: 0px;
  }
}

.nav-active {
  transform: translateX(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.toggle .line1 {
  transition: transform 0.4s ease-in;
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transition: transform 0.4s ease-in;
  transform: rotate(45deg) translate(-5px, -6px);
}
