.marker {
  width: 20px;
  height: 30px;
}

#Location {
  background-color: white;
  padding-bottom: 10%;
  padding-top: 8%;
}

h2 {
  margin-top: 2%;
  margin-bottom: 5%;
  font-size: 40px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
}

#Map {
  display: inline-block;
}

/* Collingwood Location CSS */
.Collingwood_div {
  display: block;
  text-align: center;
  padding-right: 25%;
  margin-top: 5%;
}

.StorePic {
  display: inline-block;
}

#StorePic {
  border-radius: 50%;
  width: 280px;
  height: 280px;
  margin-left: 5%;
}

#collingwood_p {
  display: inline-block;
  position: absolute;
  margin-top: 5%;
  margin-left: 5%;
}

#collingwood_social_media {
  text-align: left;
}
/* Collingwood Location CSS end*/

/* Kelowna Location CSS */
.Kelowna_div {
  display: block;
  text-align: center;
  margin-top: 5%;
  padding-right: 24%;
}

.kelowna1 {
  display: inline-block;
}

#kelowna1 {
  border-radius: 50%;
  width: 280px;
  height: 280px;
  margin-left: 5%;
}

#kelowna_p {
  display: inline-block;
  position: absolute;
  margin-top: 5%;
  margin-left: 4%;
}

#kelowna_social_media {
  text-align: left;
}
/* Kelowna Location CSS  end*/

/* BBQ Location CSS */
.BBQmarker {
  width: 35px;
}

.BBQ_div {
  display: block;
  text-align: center;
  margin-top: 5%;
  padding-right: 15%;
}

.BBQ1 {
  display: inline-block;
  margin-right: 18%;
}

#BBQ1 {
  border-radius: 50%;
  width: 280px;
  height: 280px;
}

#BBQ_p {
  display: inline-block;
  position: absolute;
  margin-left: 5%;
}

/* BBQ Location CSS  end*/

@media screen and (max-width: 900px) {
  #Location_div {
    padding-bottom: 20%;
    padding-top: 15%;
  }

  #Map {
    margin-left: 5%;
  }

  h2 {
    padding-bottom: 5%;
  }

  /* Mobile Collingwood location CSS */
  .Collingwood_div {
    margin-top: 10%;
  }

  #StorePic {
    border-radius: 50%;
    width: 300px;
    height: 300px;
    margin-top: 8%;
    margin-left: 0px;
  }

  #collingwood_p {
    display: inline-block;
    position: relative;
    margin-top: 10%;
    margin-left: 5%;
  }
  /* Mobile Collingwood location CSS end */

  /* Mobile Kelowna location CSS */
  .Kelowna_div {
    margin-top: 20%;
  }
  #kelowna1 {
    border-radius: 50%;
    width: 300px;
    height: 300px;
    margin-top: 8%;
    margin-left: 0px;
  }

  #kelowna_p {
    display: inline-block;
    position: relative;
    margin-top: 10%;
    margin-left: 5%;
  }
  /* Mobile Kelowna location CSS end */

  /* Mobile BBQ location CSS */
  .BBQ_div {
    margin-top: 20%;
  }
  #BBQ1 {
    border-radius: 50%;
    width: 300px;
    height: 300px;
    margin-top: 8%;
    margin-left: 0px;
  }

  #BBQ_p {
    display: inline-block;
    position: relative;
    margin-top: 10%;
    margin-left: 1%;
  }
  /* Mobile BBQ location CSS end */
}
