#Menu {
  background-color: #b2560f;
  /* background-color: #8a4500; */
}

#Menu_div {
  padding-top: 8%;
  padding-bottom: 5vh;
}

h1 {
  font-size: 55px;
}

#instagram {
  display: block;
  margin-left: 20%;
  margin-top: 20%;
}

#test {
  position: absolute;
}

#special {
  color: white;
  margin-top: 0px;
  margin-bottom: 1%;
  padding-top: 0px;
  margin-right: 0px;
  padding-right: 0px;
}

#special-p {
  margin-top: 40px;
  text-align: left;
  color: white;
  font-size: 17px;
  margin-bottom: 2%;
}

#menu_location {
  display: block;
  margin-right: 0%;
  margin-top: 8%;
  text-align: center;
}

#menu-p {
  font-size: 30px;
  color: white;
  text-align: center;
}

#weekly-special {
  margin-top: 15%;
  margin-right: 10%;
  float: left;
}

#menu-buttons {
  display: block;
  text-align: center;
  margin-top: 5%;
}

#Van-button {
  display: inline-block;
  margin-right: 10%;
}

#Kel-button {
  display: inline-block;
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 400ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms linear;
}

.fade-exit-done {
  opacity: 0;
}

hr {
  display: none;
}

#KelCarousel {
  display: block;
  text-align: center;
  width: 37vw;
}

#Menu_img {
  margin-top: 4%;
  height: 600%;
  width: 60vw;
}

#VanMenuDiv2 {
  display: block;
  text-align: center;
  height: 90vh;
  /* overflow-y: auto; */
}

#VanMenuimg {
  /* overflow-y: initial !important; */
  width: 37vw;
}

#Vanbutton {
  position: absolute;
  right: 0;
  z-index: 100;
}

#modalDiv {
  position: relative;
}

#buttonDiv {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;
}

/* mobile */
@media screen and (max-width: 900px) {
  #Menu_div {
    padding-top: 20%;
    padding-bottom: 0px;
  }

  #menu_location {
    margin-top: 30%;
    padding-right: 0px;
    margin-right: 5%;
    margin-left: 5%;
    padding-bottom: 10%;
  }

  #menu-p {
    font-size: 17px;
    margin-top: 0px;
    margin-bottom: 80px;
  }

  #Van-button {
    margin-right: 0px;
  }

  #Kel-button {
    display: block;
    margin-top: 15%;
    margin-bottom: 15%;
  }

  #instagram {
    margin-left: 0%;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  #special {
    margin-left: 5%;
    padding-bottom: 0px;
  }

  #special-p {
    margin-left: 5%;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  #KelCarousel {
    width: 98vw;
    height: 80vh;
  }

  .KelCarouselimg {
    height: 90vh;
  }

  hr {
    display: block;
    margin: auto;
    margin-top: 5%;
    background-color: white;
    width: 50vw;
  }

  #Menu_img {
    margin-top: 8vh;
    height: 600%;
    width: 100vw;
  }

  h1 {
    font-size: 40px;
  }

  #VanMenuDiv2 {
    width: 98vw;
    height: 80vh;
  }

  #KelMenuDiv {
    height: 80vh;
  }

  #VanMenuimg {
    width: 100%;
  }
}
