/* Contact */
#Contact_div {
  padding-top: 10%;
}
#contact_h1 {
  text-align: center;
}

h4 {
  text-align: center;
  /* color: white; */
  padding: 15px;
}

#contact_h4 {
  padding-bottom: 0;
}

#FB_contact,
#IG_contact {
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

#FB_contact {
  margin-right: 10px;
}

#IG_contact {
  margin-left: 10px;
}

#social_media {
  text-align: center;
}

#Contact_div {
  padding-bottom: 500px;
  background-color: #b2560f;
  /* background-color: chocolate; */
}

#keywords {
  color: #b2560f;
}

@media screen and (max-width: 900px) {
  #Contact_div {
    padding-top: 30%;
  }

  #contact_p {
    width: 80vw;
    margin-left: 10vw;
  }
}
